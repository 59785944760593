import * as fromChangePassword from '../change-password/store/change-password.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
    changePassword: fromChangePassword.State;
}

export const reducers: ActionReducerMap<State> = {
    changePassword: fromChangePassword.reducer,
};

export const selectMyProfileState = createFeatureSelector<State>('myProfile');
export const selectChangePasswordState = createSelector(selectMyProfileState, myProfileState => myProfileState?.changePassword);

export const selectChangePasswordStatus = createSelector(selectChangePasswordState, fromChangePassword.selectStatus);
export const selectChangePasswordErrorMessage = createSelector(selectChangePasswordState, fromChangePassword.selectErrorMessage);
