import { createAction, props, union } from '@ngrx/store';
import { ChangePasswordRequest } from '../model/change-password-request';

export const ChangePassword = createAction(
  '[ChangePassword] CHANGE_PASSWORD',
  props<{ request: ChangePasswordRequest }>(),
);
export const ChangePasswordSuccess = createAction('[ChangePassword] CHANGE_PASSWORD_SUCCESS');
export const ChangePasswordFailure = createAction(
  '[ChangePassword] CHANGE_PASSWORD_FAILURE',
  props<{ errorMessage: string }>(),
);

export const ChangePasswordActions = union({
  ChangePassword,
  ChangePasswordSuccess,
  ChangePasswordFailure,
});
