import {
  ChangePasswordActions,
  ChangePassword,
  ChangePasswordSuccess,
  ChangePasswordFailure
} from './change-password.actions';

export interface State {
  status: string;
  errorMessage: string;
}

export const initialState: State = {
  status: null,
  errorMessage: null
};

export function reducer(state = initialState, action: typeof ChangePasswordActions): State {
  switch (action.type) {
    case ChangePassword.type: {
      return { ...state, status: 'Changing password ...', errorMessage: null };
    }

    case ChangePasswordSuccess.type: {
      return { ...state, status: null, errorMessage: null };
    }

    case ChangePasswordFailure.type: {
      return {
        ...state,
        status: 'Failed to change password.',
        errorMessage: action.errorMessage
      };
    }

    default:
      return state;
  }
}

export const selectStatus = (state: State) => state && state.status;
export const selectErrorMessage = (state: State) => state && state.errorMessage;
